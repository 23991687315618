<template>
    <div>
        <Esencia/>
    </div>
</template>

<script>
    import Esencia from '@/components/Esencia'
    export default {
        components:{
            Esencia
        }
    }
</script>

<style lang="scss" scoped>

</style>